import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_HOST_API || 
  import.meta.env.VITE_HOST_API_AZURE || 
  "https://backend-abilityworks-gpgcexerf3c8ftcf.australiasoutheast-01.azurewebsites.net",
  withCredentials: true, // Include credentials if needed for auth
});


axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

